'use client';

import type { HorVerLayout, UIComponentProps } from '@wolfejs/ui/types/UI';
import type { FieldValues } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { CheckboxNative as Checkbox } from '../Checkbox/CheckboxNative';
import { DatePicker } from '../Datepicker';
import { ImagePicker } from '../ImagePicker/ImagePicker';
import { Input } from '../Input/Input';
import { InputCurrency } from '../Input/InputCurrency';
import { InputNumber } from '../Input/InputNumber';
import { InputPhone } from '../Input/InputPhone';
import { Select, SelectRemote } from '../Select';
import { Slider } from '../Slider';
import { Switch, SwitchWithLabel } from '../Switch';
import { Textarea } from '../Textarea/Textarea';
import { FormContext, type FormContextProps } from './FormContext';
import { FormGroup } from './FormGroup';
import type { FormSchemaEntry } from './types';

export type FormFieldProps = UIComponentProps & {
  name: string;
  context?: FormContextProps<FieldValues> | null;
  error?: string;
  layout?: HorVerLayout;
};

type SchemaEntryWithName = {
  name: string;
};

function FormFieldComp({ name, context, layout, ...otherProps }: FormFieldProps) {
  if (!context) return null;

  const entry = context.schema.find((entry: SchemaEntryWithName) => entry.name == name);
  const control = context.control;
  if (!entry) return <div>entry not found</div>;

  const errors = context.formState.errors;

  const fieldError = errors?.[entry.name];

  const registerProps = context.register(entry.name);

  function renderField(entry: FormSchemaEntry) {
    const commonInputProps = {
      id: entry.name,
      ...registerProps,
    };

    let loadOptions;

    switch (entry?.type) {
      case 'textarea':
        return <Textarea {...entry?.props} {...commonInputProps} />;
      case 'number-formatted':
        return (
          <Controller
            control={control}
            name={entry.name}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputNumber
                {...entry?.props}
                {...commonInputProps}
                onValueChange={onChange}
                onBlur={onBlur}
                value={value}
              />
            )}
          />
        );
      case 'phone':
        return (
          <Controller
            control={control}
            name={entry.name}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputPhone
                {...entry?.props}
                {...commonInputProps}
                onValueChange={onChange}
                onBlur={onBlur}
                value={value}
                fullWidth
              />
            )}
          />
        );
      case 'currency':
        return (
          <Controller
            control={control}
            name={entry.name}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputCurrency
                {...entry?.props}
                {...commonInputProps}
                onValueChange={onChange}
                onBlur={onBlur}
                value={value}
              />
            )}
          />
        );
      case 'checkbox':
        return (
          <Controller
            control={control}
            name={entry.name}
            render={({ field: { onChange, value } }) => (
              <Checkbox {...entry?.props} {...commonInputProps} checked={value} onCheckedChange={onChange} />
            )}
          />
        );
      case 'select':
        return (
          <Controller
            control={control}
            name={entry.name}
            render={({ field: { onChange, onBlur, value } }) => (
              <Select id={entry.name} {...entry.props} onChange={onChange} onBlur={onBlur} value={value} />
            )}
          />
        );
      case 'select-remote':
        return (
          <Controller
            control={control}
            name={entry.name}
            render={({ field: { onChange, value } }) => (
              <SelectRemote
                fullWidth
                {...entry.props}
                onChange={onChange}
                value={value}
                defaultValue={value}
                defaultInputValue={value?.label}
              />
            )}
          />
        );
      case 'switch':
        return (
          <Controller
            control={control}
            name={entry.name}
            render={({ field: { onChange, onBlur, value } }) => {
              const componentToRender =
                entry.label && entry.secondaryLabel ? (
                  <SwitchWithLabel
                    {...entry?.props}
                    {...commonInputProps}
                    className="mt-1"
                    label={entry.label}
                    secondaryLabel={entry?.secondaryLabel}
                    onCheckedChange={onChange}
                    onBlur={onBlur}
                    checked={value}
                    value={value}
                    showRequiredIndicator={entry?.showRequiredIndicator}
                    required={entry?.showRequiredIndicator}
                    id={entry.name}
                  />
                ) : (
                  <Switch
                    {...entry?.props}
                    {...commonInputProps}
                    onCheckedChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                );
              return componentToRender;
            }}
          />
        );

      case 'slider':
        return (
          <Controller
            control={control}
            name={entry.name}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <Slider id={entry.name} {...entry?.props} onValueChange={onChange} onBlur={onBlur} value={value} />
              );
            }}
          />
        );
      case 'imagePicker':
        return (
          <Controller
            control={control}
            name={entry.name}
            render={({ field: { onChange, value } }) => {
              return <ImagePicker {...entry?.props} {...commonInputProps} value={value} onChange={onChange} />;
            }}
          />
        );
      case 'datepicker':
        return (
          <Controller
            control={control}
            name={entry.name}
            render={({ field: { onChange, value } }) => (
              <DatePicker id={entry.name} {...entry.props} onChange={onChange} initialValue={value} />
            )}
          />
        );
      default:
        return <Input fullWidth {...entry?.props} {...commonInputProps} type={entry.type} />;
    }
  }

  function isTopLabelHidden(entry: FormSchemaEntry): boolean {
    return !!(
      (entry.type === 'switch' && entry.label && entry.secondaryLabel) ||
      (entry.type === 'checkbox' && entry?.props?.labelAppend)
    );
  }

  return (
    <FormGroup
      layout={layout}
      label={entry?.label}
      fieldName={entry?.name}
      className={entry?.formFieldClassName}
      showRequiredIndicator={entry?.showRequiredIndicator}
      hideTopLabel={isTopLabelHidden(entry)}
      {...otherProps}
    >
      {/* {generateFormField({ entry, register: context.register, key: entry.name })} */}
      {entry && renderField(entry)}
      {fieldError && <div className="text-error">{fieldError?.message as string}</div>}
    </FormGroup>
  );
}

export function FormField(props: FormFieldProps) {
  return (
    <FormContext.Consumer>
      {context => {
        return <FormFieldComp {...props} context={context} />;
      }}
    </FormContext.Consumer>
  );
}
